import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getListNewsSelector } from 'src/redux/selectors'
import { getListNews } from 'src/redux/thunks'
import { getDifference } from 'src/utils/time'
import styles from './styles.module.scss'
import { MainWidget } from '../MainWidget'
import Link from 'next/link'
import { useLatestNewsArticle, useListPinned } from 'src/state'
import {
  getLatestNewsArticle,
  getNewsArticlePinned,
} from 'src/services/newsArticleServices'
import { getListPinned } from 'src/services/listServices'

export const NewsFeedWidget = () => {
  const data = useSelector(getListNewsSelector)
  const dispatch = useDispatch()
  const latestNewsItem = useLatestNewsArticle()
  const pinnedList = useListPinned()

  useEffect(() => {
    if (!pinnedList) {
      dispatch(getListPinned())
    }
    if (!latestNewsItem) {
      dispatch(getLatestNewsArticle())
    }
    if (data.length === 0) {
      dispatch(getListNews())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const renderLatestItem = (item, isPinned) => {
    const baseUrl = isPinned
      ? 'https://coin360.com/list/'
      : 'https://coin360.com/news/'
    return (
      <div className={styles.item}>
        <Link href={`${baseUrl}${item.data?.attributes?.slug}`}>
          <a
            className={styles.titleItem}
            rel='noopener noreferrer nofollow'
            target='_blank'
          >
            {item.data?.attributes?.title}
          </a>
        </Link>
        <Link href={`${baseUrl}${item.data?.attributes?.slug}`}>
          <div className={styles.info}>
            <div className={styles.latestName}>COIN360</div>
            <div className={styles.readNow}>Read now!</div>
          </div>
        </Link>
      </div>
    )
  }

  const renderNewsItem = item => {
    const date = getDifference(item.date)

    return (
      <div className={styles.item}>
        <Link href={item.link}>
          <a
            className={styles.titleItem}
            rel='noopener noreferer nofollow'
            target='_blank'
          >
            {item.title}
          </a>
        </Link>
        <div className={styles.info}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.date}>{date}</div>
        </div>
      </div>
    )
  }

  return (
    <MainWidget title={'Live news feed'}>
      {latestNewsItem.data &&
      Object.keys(latestNewsItem.data).length > 0 &&
      pinnedList.data &&
      Object.keys(pinnedList.data).length > 0 ? (
        <div className={styles.newsContainer}>
          {renderLatestItem(latestNewsItem, false)}
          {renderLatestItem(pinnedList, true)}
          {data.map(item => renderNewsItem(item))}
        </div>
      ) : (
        <div className={styles.placeholder}></div>
      )}
    </MainWidget>
  )
}
